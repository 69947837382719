import React from "react";

import { About, Footer, Header, Skills, Testimonial, Work } from "./containers";
import { Navbar } from "./components";
import "./App.scss";
import { AppWrap } from "./wrapper";

const App = () => {
  return (
    <div className="app">
      <AppWrap />
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
      {/* Attributions */}
      <div className="flex flex-col justify-center items-center text-center text-gray-500 text-xs">
        <span>Atribució:</span>
        <span>
          <a
            className=" underline"
            href="https://www.freepik.com/free-vector/3d-growth-chart-diagram-with-shadow_5548502.htm#query=graph%20up&position=26&from_view=keyword&track=ais"
          >
            Imatge del gràfic feta per starline
          </a>
          &nbsp;a Freepik
        </span>
      </div>
    </div>
  );
};

export default App;
