import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    // TODO: Ask copilot-chat to explain this line
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client
      .create(contact)
      .then((data) => {
        setIsFormSubmitted(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <>
      <h2 className="head-text">Posem-nos en contacte!</h2>

      <div className="app__footer-cards">
        {/* Email */}
        <div className="app__footer-card">
          <img
            src={images.email}
            alt="email"
          />
          <a
            href="mailto:albert@thegoodsamurai.com"
            target="_blank"
            rel="noreferrer"
            className="p-text"
          >
            albert@thegoodsamurai.com
          </a>
        </div>
        {/* Mobile */}
        <div className="app__footer-card">
          <img
            src={images.mobile}
            alt="mobile"
          />
          <a
            href="tel:+34663746221"
            target="_blank"
            rel="noreferrer"
            className="p-text"
          >
            +34 663746221
          </a>
        </div>
        {/* WhatsApp */}
        <div className="app__footer-card">
          <img
            src={images.whatsapp}
            alt="whatsapp"
          />
          <a
            href="https://wa.me/34663746221"
            target="_blank"
            rel="noreferrer"
            className="p-text"
          >
            +34 663746221
          </a>
        </div>
      </div>

      {/* Form */}
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            {/* Name */}
            <input
              name="name"
              className="p-text"
              type="text"
              placeholder="Your name"
              value={name}
              onChange={handleChangeInput}
            />
            {/* Email */}
            <input
              name="email"
              className="p-text"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
            <button
              className="p-text"
              type="button"
              onClick={handleSubmit}
            >
              {loading ? "Sending" : "Send message"}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Gràcies per contactar-me, em posaré en contacte amb tú el més pronte possible!</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(MotionWrap(Footer, "app__footer"), "contact", "app__whitebg");
