import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Un plaer! Em dic </p>
              <h1 className="head-text">Albert</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p
              className="p-text"
              style={{ textTransform: "capitalize" }}
            >
              Desenvolupador Web
            </p>
            <p
              className="p-text"
              style={{ textTransform: "capitalize" }}
            >
              Director màrqueting
            </p>
            <p
              className="p-text"
              style={{ textTransform: "capitalize" }}
            >
              Freelancer
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img
          src={images.profile}
          alt="profile_bg"
        />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.react, images.laravel, images.csharp].map((circle, index) => (
          <div
            className="circle-cmp app__flex"
            key={`circle-${index}`}
          >
            <img
              src={circle}
              alt="profile_bg"
            />
          </div>
        ))}
      </motion.div>
      <motion.div
        whileInView={{ opacity: [0, 1], y: [50, 0] }}
        transition={{ duration: 1, delay: 1 }}
        className="absolute bottom-0 left-0 w-full flex justify-evenly p-4 z-10"
      >
        <motion.a
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          href="#about"
          // Button with a formal office style with tailwind
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-xl"
        >
          Vull més informació
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          href="#work"
          // Button with a playful style with tailwind
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full shadow-xl"
        >
          Ensenya'm els projectes!
        </motion.a>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
